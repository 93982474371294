// 2024-11-08 k_nagayama submit追加
const videoExtensions: string[] = [
  "asf",
  "avi",
  "flv",
  "m2ts",
  "m4v",
  "mkv",
  "mov",
  "mp4",
  "mpeg",
  "mpg",
  "ogg",
  "ogm",
  "ogv",
  "qt",
  "ts",
  "vob",
  "webm",
  "wmv",
];

const imageExt = [
  "apng",
  "avif",
  "gif",
  "jpeg",
  "jpg",
  "png",
  "tif",
  "tiff",
  "webp",
]

const audioExt = [
  "mp3",
  "wav",
]

const docExt = [
  "doc",
  "docx",
  "htm",
  "html",
  "pdf",
  "ppt",
  "pptx",
  "txt",
  "xls",
  "xlsx",
]


export type fileType = "VIDEO" | "IMAGE" | "AUDIO" | "DOC"|"OTHER"

export const getFileType = (
  fileName: string,
): fileType => {
  const splitedFileName = fileName.split(".");
  const ext = splitedFileName[splitedFileName.length - 1];
  if (videoExtensions.includes(ext)) {
    return "VIDEO";
  }
  if (imageExt.includes(ext)) {
    return "IMAGE";
  }
  if (audioExt.includes(ext)) {
    return "AUDIO";
  }
  if (audioExt.includes(ext)) {
    return "AUDIO";
  }
  if (docExt.includes(ext)) {
    return "DOC";
  }
  return "OTHER";
};
// 2024-11-08 k_nagayama submit追加 ここまで