// 2024-11-08 k_nagayama submit追加
import { ReactNode, useCallback, useState } from 'react';
import { BaseProps } from '../@types/common';
import useSubmitFiles from '../hooks/useSubmitFiles';
import { useTranslation } from "react-i18next";
import DialogFilePreview from "./DialogFilePreview";
import ListItemFile from './ListItemFile';
import { PiCircleNotch } from 'react-icons/pi';

type Props = BaseProps & {
  userId?: string;
  onClick: (botId: string) => void;
  children?: ReactNode;
};

const ListItemUserFiles: React.FC<Props> = (props) => {
  const { t } = useTranslation();

  const [previewFileUrl, setPreviewFileUrl] = useState<string>();
  const [previewFileName, setPreviewFileName] = useState("");
  const [isModalOpen, setModalOpen] = useState(false);

  const {
    myFiles,
    isLoading,
    getPresignedDownloadUrl,
  } = useSubmitFiles(props.userId);


  const onClickFile = useCallback(async (fileId: string, fileName: string) => {
    setPreviewFileUrl((await getPresignedDownloadUrl(fileId)).data);
    setPreviewFileName(fileName)
    setModalOpen(true);
  }, [getPresignedDownloadUrl]);

  return (
    <>
      {previewFileUrl && <DialogFilePreview
        fileUrl={previewFileUrl}
        fileName={previewFileName}
        isOpen={isModalOpen}
        onClose={() => { setModalOpen(false); }}
      ></DialogFilePreview >}
      {(!myFiles || myFiles?.length === 0) && (
        <>
          {isLoading ? (
            <div className="flex flex-col items-center p-4 py-[25px]">
              <div className="animate-spin">
                <PiCircleNotch size={100} />
              </div>
            </div>
          ) : (
            <div className="flex size-full items-center justify-center italic text-dark-gray">
              {t('submitFiles.label.noFiles')}
            </div>
          )}
        </>
      )}
      {myFiles?.map((file) => (
        <ListItemFile
          key={file.id}
          file={file}
          onClick={() => { onClickFile(file.id, file.title) }}
          className="last:border-b-0">
        </ListItemFile>
      ))}
    </>
  );
};

export default ListItemUserFiles;
// 2024-11-08 k_nagayama submit追加 ここまで
