import React, { useState } from 'react';
import { BaseProps } from '../@types/common';
import { IoTriangle } from "react-icons/io5";
import { twMerge } from 'tailwind-merge';

type Props = BaseProps & {
  title: string;
  children: React.ReactNode;
};

const ListItemCollapse2: React.FC<Props> = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (<>

    <div
      className={`${props.className ?? ''
        } relative flex w-full justify-between border-b border-light-gray`}>
      <div
        className='h-full grow bg-aws-paper p-2 cursor-pointer hover:brightness-90'
        onClick={toggle}>
        <div className="overflow-hidden text-ellipsis text-sm font-semibold flex">
          <div className='mt-1 me-5'>
            <IoTriangle className={twMerge('transition-transform', isOpen ? 'rotate-[60deg]' : '-rotate-[30deg]')} />
          </div>{props.title}
        </div>

        <div className="mt-1 overflow-hidden text-ellipsis text-xs">
        </div>
      </div>

      <div className="absolute right-0 flex h-full justify-between ">
        <div className="w-10 bg-gradient-to-r from-transparent to-aws-paper"></div>
        <div className="flex items-center  gap-2 bg-aws-paper pl-2">
        </div>
      </div>
    </div >
    {isOpen && <>
      {props.children}
    </>
    }
  </>);
};

export default ListItemCollapse2;
