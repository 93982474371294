import React, { useCallback } from 'react';
import { BaseProps } from '../@types/common';
import Button from './Button';
import { saveAs } from 'file-saver';

type Props = BaseProps & {
  icon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  disabled?: boolean;
  text?: boolean;
  outlined?: boolean;
  loading?: boolean;
  onClick?: (downloadFile: string | Blob) => void;
  children: React.ReactNode;
  downloadFile: string | Blob
};

const ButtonImageSave: React.FC<Props> = (props) => {
  const onClick = useCallback(() => {
    if (props.onClick) {
      props.onClick(props.downloadFile)
    } else {
      saveAs(props.downloadFile)
    }
  },
    [props]
  );

  return (
    <Button
      {...props}
      onClick={onClick}
    ></Button>
  );
};

export default ButtonImageSave;
