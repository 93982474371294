// 2024-11-08 k_nagayama submit追加
import React from 'react';
import { useTranslation } from 'react-i18next';
import ButtonFileChoose from '../components/ButtonFileChoose';
import useSubmitFiles from '../hooks/useSubmitFiles';
import ListItemUserFile from '../components/ListItemUserFiles';

const SubmitFilesPage: React.FC = () => {
  const { t } = useTranslation();

  const {
    postSubmitFile,
  } = useSubmitFiles();

  const onClickNewFileButton = (fileList: FileList) => {
    for (const file of fileList) {
      postSubmitFile(file);
    }
  }

  return (
    <div className="flex h-full justify-center">
      <div className="w-2/3">
        <div className="size-full pt-8">
          <div className="flex items-end justify-between">
            <div className="flex items-center gap-2">
              <div className="text-xl font-bold">{t('submitFiles.label.myFiles')}</div>
            </div>
            <ButtonFileChoose
              onChange={onClickNewFileButton}
              accept={"*/*"}>
              {t('submitFiles.button.submitFile')}
            </ButtonFileChoose>
          </div>
          <div className="mt-2 border-b border-gray"></div>

          <div className="h-4/5 overflow-x-hidden overflow-y-scroll border-b border-gray pr-1 scrollbar-thin scrollbar-thumb-aws-font-color/20 ">
            <ListItemUserFile
              onClick={() => { }}
              className="last:border-b-0">
            </ListItemUserFile>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubmitFilesPage;
// 2024-11-08 k_nagayama submit追加 ここまで
